

















































































































































































































































































































































































































































































import { Component, Vue } from 'vue-property-decorator';
import { readUserProfile } from '@/store/main/getters';
import { readUserWallet } from '@/store/main/getters';
import { readUserTransactions, readUserInwardTransactions, readUserOutwardTransactions } from '@/store/main/getters';
import { readConstants } from '@/store/main/getters';
import { dispatchGetConstants } from '@/store/main/actions';
import { dispatchGetUserWallet } from '@/store/main/actions';
import { dispatchGetUserTransactions, dispatchGetUserInwardTransactions, dispatchGetUserOutwardTransactions } from '@/store/main/actions';

@Component
export default class Wallet extends Vue {
  public headers = [
    { text: '時間', sortable: true, value: 'time_created', align: 'left',},
    { text: '數量', sortable: true, value: 'amount', align: 'left',},
    { text: '狀態', sortable: true, value: 'status', align: 'left',},
    { text: '地址', sortable: true, value: 'to_address', align: 'left',},
    { text: '交易 ID', sortable: true, value: 'txID', align: 'left',},
  ]

  public dialogDeposit = false
  
  get userProfile() {
    return readUserProfile(this.$store);
  }

  get userWallet() {
    return readUserWallet(this.$store);
  }

  get userTransactions() {
    return readUserTransactions(this.$store);
  }

  get userInwardTransactions() {
    return readUserInwardTransactions(this.$store);
  }

  get userOutwardTransactions() {
    return readUserOutwardTransactions(this.$store);
  }

  async mounted() {
    await dispatchGetConstants(this.$store);
    await dispatchGetUserWallet(this.$store);
    await dispatchGetUserTransactions(this.$store);
    await dispatchGetUserInwardTransactions(this.$store);
    await dispatchGetUserOutwardTransactions(this.$store);
  }

  // update version
  checkVersion() {
    const constants = readConstants(this.$store);

    let onlineVersion = "none";
    if (constants) {
      onlineVersion = constants.version;
    }

    let localVersion = localStorage.getItem('vers');
    let updateSignal = localStorage.getItem('upt');

    // check if we need to fresh the browser
    if (onlineVersion != "none" && localVersion != onlineVersion) { // detect newer online version
      localStorage.setItem('vers', onlineVersion);
      window.location.reload();
    } else if (updateSignal == 'refresh') { // detect refresh signal
      localStorage.setItem('upt', 'no');
      window.location.reload();
    }
  }

  // adaptive screen size
  public isMobile = false;

  beforeDestroy() {
    if (typeof window !== 'undefined') {
      window.removeEventListener('resize', this.onResize);
    }
  }

  created() {
    window.addEventListener('resize', this.onResize);
    this.onResize();
    this.checkVersion();
  }

  onResize() {
    this.isMobile = window.innerWidth < 960;
  }
}
